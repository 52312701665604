import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import AlertTop from '@components/Modules/MediaPush/AlertTop'
import CounterPopup from '@components/Modules/MediaPush/CounterPopup'
import FlapButton from '@components/Modules/MediaPush/FlapButton'
import SquarePop from '@components/Modules/MediaPush/SquarePop'
import {
  getActionByType,
  getCodeFromType,
  getCodes,
  getQueryEventTracker,
  getSectionFromCode,
  getTypeFromCode,
} from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import { Action, EventTracker, RealEstateData, RealEstateDataPage } from 'types'
import { useLocation } from '@reach/router'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'
import $ from 'jquery'
import Slider from 'react-slick'
import ReactDOM from 'react-dom'
import { informCustomEvent } from '@helpers/helper.analytics'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'

const isBrowser = typeof window !== 'undefined'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Modules = ({
  development_id,
  property_id,
  novelty_id,
  pages,
  page,
  global_data,
}: {
  development_id?: string
  novelty_id?: string
  property_id?: string
  page?: RealEstateDataPage
  pages?: RealEstateDataPage[]
  global_data: RealEstateData
}) => {
  const API_KEY = global_data.pageConfig.keys.tokko

  const { pathname } = useLocation()

  const sliderRef = useRef<any>()

  const [sendTrackerEvent] = useSendTrackerEvent()

  const isCompiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'

  const currentPage = pages?.find(
    (page) =>
      (pathname.replaceAll('/', '') === page.path.replaceAll('/', '') && !isCompiler) ||
      (isCompiler &&
        pathname !== '/' &&
        pathname.replace(global_data.divisionPath!, '').replaceAll('/', '') ===
          page.path.replaceAll('/', '')) ||
      (pathname.includes('/emprendimiento/') && page.path === '/desarrollo_detalle') ||
      (pathname.includes('/propiedad/') && page.path === '/propiedad_detalle') ||
      (pathname.includes('/novedad/') && page.path === '/novedad_detalle'),
  )

  const { data: allPushData, isLoading: loading } = useGetPushQuery({
    page: currentPage?.id || '999',
    data_id: development_id || property_id || novelty_id || '',
  })
  const { data: property } = useQueryProperty(property_id, API_KEY)
  const { data: development } = useQueryDevelopment(development_id, API_KEY)

  const slider_action = getActionByType(
    allPushData,
    getCodeFromType('Slider de Imagenes Encabezado'),
  )
  const image_action = getActionByType(allPushData, getCodeFromType('Imagen Encabezado'))
  const video_action = getActionByType(allPushData, getCodeFromType('Video Encabezado'))

  const [clientHeight, setClientHeight] = useState(0)

  useEffect(() => {
    setClientHeight(document?.getElementById('header')?.clientHeight ?? 0)
    //itero en currentPage y busco si alguno de sus objetos .type tiene incluido el nombre "hero"

    const hasHero = currentPage?.components.find((component) =>
      component.type.toLowerCase().includes('hero'),
    )
    if (allPushData) {
      const element = document.getElementById('injected-component-hero')
      if (element) {
        element.remove()
      }
      if (!hasHero) {
        const container = document.createElement('div')
        container.id = 'injected-component-hero'
        $('header').after(container)
        ReactDOM.render(<PushRelative />, container)
      }
    }
  }, [allPushData])

  const getSize = () => (screen.width < 992 ? false : true)
  const isDesktop = () => (screen.width < 992 ? false : true)

  const goTo = () => {
    if (video_action[0].url_destiny) {
      if (isBrowser) {
        window.open(
          video_action[0].url_destiny + video_action[0].text_utm,
          '_blank',
          'noopener,noreferrer',
        )
      }
      informCustomEvent(
        'PUSH_' +
          currentPage?.id +
          '_' +
          (currentPage?.path.includes('novedad_detalle') ? novelty_id : '0') +
          '_' +
          video_action[0].id,
      )
      sendTrackerEvent(
        getQueryEventTracker({
          page: currentPage!,
          action: video_action[0],
          type: 'PUSH',
          data_id: currentPage?.path.includes('novedad_detalle') ? novelty_id : '0',
          url: location.href,
        }),
      )
    }
  }

  const PushRelative = () => {
    useEffect(() => {
      if (video_action.length > 0) {
        informCustomEvent(
          'SHOW_' +
            currentPage?.id +
            '_' +
            (currentPage?.path.includes('novedad_detalle') ? novelty_id : '0') +
            '_' +
            video_action[0].id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: currentPage!,
            action: video_action[0],
            type: 'SHOW',
            data_id: currentPage?.path.includes('novedad_detalle') ? novelty_id : '0',
            url: location.href,
          }),
        )
      }
      if (image_action.length > 0) {
        informCustomEvent(
          'SHOW_' +
            currentPage?.id +
            '_' +
            (currentPage?.path.includes('novedad_detalle') ? novelty_id : '0') +
            '_' +
            image_action[0].id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: currentPage!,
            action: image_action[0],
            type: 'SHOW',
            data_id: currentPage?.path.includes('novedad_detalle') ? novelty_id : '0',
            url: location.href,
          }),
        )
      }
      if (slider_action.length > 0) {
        informCustomEvent(
          'SHOW_' +
            currentPage?.id +
            '_' +
            (currentPage?.path.includes('novedad_detalle') ? novelty_id : '0') +
            '_' +
            slider_action[0].id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: currentPage!,
            action: slider_action[0],
            type: 'SHOW',
            data_id: currentPage?.path.includes('novedad_detalle') ? novelty_id : '0',
            url: location.href,
          }),
        )
      }
      setClientHeight(document?.getElementById('header')?.clientHeight ?? 0)
    }, [loading])
    return (
      <div
        onClick={() => validateGoTo()}
        className={`background-hero ${slider_action.length > 0 && 'index-top'}`}
      >
        {image_action.length > 0 ? (
          <RenderPushImage />
        ) : slider_action.length > 0 ? (
          <RenderPushSlider />
        ) : video_action.length > 0 ? (
          <>
            <RenderPushVideo />
          </>
        ) : null}
      </div>
    )
  }

  const RenderPushSlider = () => {
    return (
      <div
        id="hero-main"
        style={{ height: `calc(100vh - ${clientHeight}px)` }}
        className="banner-main a inyected"
      >
        <Slider
          {...settings}
          ref={sliderRef}
        >
          {slider_action
            .at(0)
            ?.files.map(
              ({ file, url_destiny }: { file: string; url_destiny: string }, index: number) => (
                <div
                  key={index}
                  className="position-relative "
                >
                  <div
                    className={`${url_destiny && 'cursor-pointer'}`}
                    onClick={() => (url_destiny ? window?.open(url_destiny) : null)}
                  >
                    <div className="opacity"></div>
                    <img
                      src={file}
                      style={{ maxHeight: `calc(100vh - ${clientHeight}px)` }}
                      className="image-push"
                      alt=""
                    />
                  </div>
                </div>
              ),
            )}
        </Slider>
        <div className={'arrows-left onTop zindex-1'}>
          <div
            className="arrow"
            onClick={() => sliderRef.current.slickPrev()}
          >
            {' '}
            <i></i> <i></i>
          </div>
        </div>
        <div className={'arrows-right onTop zindex-1'}>
          <div
            className="arrow"
            onClick={() => sliderRef.current.slickNext()}
          >
            {' '}
            <i></i> <i></i>{' '}
          </div>
        </div>
      </div>
    )
  }

  const RenderPushImage = () => {
    return (
      <img
        style={{ maxHeight: `calc(100vh - ${clientHeight}px)` }}
        className={
          'image-push w-100 object-fit-cover ' +
          (image_action.at(0)?.url_destiny && 'cursor-pointer')
        }
        onClick={() =>
          image_action.at(0)?.url_destiny ? window?.open(image_action.at(0)?.url_destiny) : null
        }
        src={image_action.at(0)?.files.at(0)?.file}
        alt=""
      />
    )
  }

  const RenderPushVideo = () => {
    return (
      <video
        className={'d-lg-block w-100 object-fit-cover '}
        style={{ maxHeight: `calc(100vh - ${clientHeight}px)` }}
        loop
        autoPlay
        muted
      >
        <source
          src={
            isDesktop()
              ? video_action[0].files.find(
                  (video: { device: string }) => video.device === 'desktop',
                )?.file || video_action[0].files[0].file
              : video_action[0].files.find((video: { device: string }) => video.device === 'mobile')
                  ?.file || video_action[0].files[0].file
          }
          type="video/mp4"
        />
      </video>
    )
  }

  const validateGoTo = () => {
    if (video_action.length > 0) {
      goTo()
    } else if (image_action.length > 0) {
      goToImage(image_action[0])
    } else if (slider_action.length > 0) {
      goToImage(slider_action[0], 0)
    }
  }

  const goToImage = (action: Action, index?: number) => {
    if (index !== undefined && action?.files[index].url_destiny) {
      window?.open(action?.files[index].url_destiny)
    } else if (action.url_destiny) {
      window?.open(action?.url_destiny)
    }

    informCustomEvent(
      'PUSH_' +
        currentPage?.id +
        '_' +
        (currentPage?.path.includes('novedad_detalle') ? novelty_id : '0') +
        '_' +
        action.id,
    )
    sendTrackerEvent(
      getQueryEventTracker({
        page: currentPage!,
        action: action,
        type: 'PUSH',
        data_id: currentPage?.path.includes('novedad_detalle') ? novelty_id : '0',
        url: location.href,
      }),
    )
  }

  return (
    !loading &&
    currentPage?.id &&
    isBrowser && (
      <>
        {getActionByType(allPushData, getCodeFromType('Solapa Flotante')).map(
          (action: Action, index: number) => (
            <FlapButton
              key={index}
              property={property}
              development={development}
              properties={action.properties}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              utmValue={action.texts.text_utm}
              linkTxt={action.texts.text_limited}
              alignment={action.alignment}
              type={getTypeFromCode(action.type)}
              action={action}
              showOn={getSectionFromCode(action.section)}
              page={currentPage}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Timer')).map(
          (action: Action, index: number) => (
            <CounterPopup
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              title={action.title}
              showOn={getSectionFromCode(action.section)}
              band={action.texts.text_band}
              text={action.texts.text}
              utmValue={action.texts.text_utm}
              type={getTypeFromCode(action.type)}
              eventDate={action.date_start}
              page={currentPage}
              action={action}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Vertical')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={action.alignment}
              flat={false}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
              page={currentPage}
              action={action}
              text_popup={action.text_popup}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Cuadrado')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={''}
              flat={false}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
              page={currentPage}
              action={action}
              text_popup={action.text_popup}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Panoramico')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={''}
              flat={true}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
              page={currentPage}
              action={action}
              text_popup={action.text_popup}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('Barra Encabezado Timer')).map(
          (action: Action, index: number) => (
            <AlertTop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              textAlert={action.texts?.text}
              showOn={getSectionFromCode(action.section)}
              type={getTypeFromCode(action.type)}
              utmValue={action.texts.text_utm}
              eventDate={action.date_start}
              page={currentPage}
              action={action}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('Barra Encabezado')).map(
          (action: Action, index: number) => (
            <AlertTop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              textAlert={action.texts?.text}
              type={getTypeFromCode(action.type)}
              utmValue={action.texts.text_utm}
              eventDate={action.date_start}
              showOn={getSectionFromCode(action.section)}
              page={currentPage}
              action={action}
            />
          ),
        )}
      </>
    )
  )
}

export default connect((state) => ({}), null)(Modules)
