import { Novelty } from 'types'

export const getTextShort = (text: string, length: number) => {
  return text?.length > length ? text.substr(0, length) + '...' : text
}

export const getDateTime = (date: any, full = false, onlyNumber = false) => {
  if (onlyNumber && date) {
    const split = date.split('T')[0]
    const splitTime = split.split('-')
    return `${splitTime[2]}.${splitTime[1]}.${splitTime[0].split('20')[1]}`
  }
  const Month: any = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  }
  if (date) {
    const dateTime = date.split(':')
    let times = dateTime[0].split(' ')
    times = times[0].split('-')
    return full
      ? [times[2].split('T')[0]] + ' de ' + Month[parseInt(times[1])] + ' de ' + times[0]
      : Month[parseInt(times[1])] + ' ' + times[0]
  }
  return 'Enero 2020'
}

export const formatDate = (date: any, format: string = '') => {
  if (format || format?.length > 0) {
    if (format === 'long') {
      // ex. 22 de agosto 2023
      return getDateTime(date, true)
    }
    if (format === 'short') {
      // ex. 22.08.23
      return getDateTime(date, false, true)
    }
  } else {
    // ex. Agosto 2023
    return getDateTime(date, false)
  }
}

export const getFeatured = (novelties: Novelty[] | undefined) => {
  if (novelties) {
    return novelties.filter((item) => item.featured === true)
  }
}

export const getThree = (novelties: Novelty[]) => {
  if (novelties) {
    return novelties.slice(0, 3)
  }
  return []
}

export const orderNovelties = (novelties: Novelty[], siteId?: string) => {
  //CONSULTAR*
  const noveltiesPair = novelties.filter(
    (novelty) =>
      novelty.sites.find((site) => site.toString() === siteId) || novelty.sites.length == 0,
  )

  for (let i = 0; i < noveltiesPair.length; i++) {
    const date: any[] = noveltiesPair[i].date_published.split('T')[0].split('-')
    noveltiesPair[i].parseDate = new Date(date[0].toString(), date[1] - 1, date[2])
    noveltiesPair[i].parseDate = new Date(date[0].toString(), date[1] - 1, date[2])
  }
  const sortedNovelties = noveltiesPair.sort(
    (a: any, b: any) => b.parseDate.getTime() - a.parseDate.getTime(),
  )

  return sortedNovelties.map((novelty) => {
    delete novelty.parseDate
    return novelty
  })
}

// Copy novelties array
const copyNovelties = (novelties: Novelty[]) => {
  if (Array.isArray(novelties) && novelties?.length > 0) {
    let auxNovelties = []
    for (let i = 0; i < novelties?.length; i++) {
      auxNovelties[i] = novelties[i]
    }
    return auxNovelties
  }
  return []
}

// First newest novelty[], ordered by date_published prop
export const orderbyDate = (novelties: Novelty[]) => {
  // let auxNov = copyNovelties(novelties);
  return novelties
  // return (auxNov && Array.isArray(auxNov) && auxNov.length > 0)
  //     ? auxNov?.sort((n1,n2) => new Date(n2?.date_published) - new Date(n1?.date_published))
  //     : []
}

// Not filter, order by featured first
export const orderbyFeatured = (novelties: Novelty[]) => {
  let auxNov = copyNovelties(novelties)
  return auxNov && Array.isArray(auxNov) && auxNov.length > 0
    ? auxNov?.sort((n1, n2) => Number(n2?.featured) - Number(n1?.featured))
    : []
}
