import { useGetPropertyQuery as useGetPropertyQueryTokko } from '@apis/middlewareTokkoApi/properties'
import { useGetPropertyQuery as useGetPropertyQueryMediacore } from '@apis/mediacore/properties'
import { useGetPropertyMiddlewareQuery as useGetPropertyMiddlewareQuery } from '@apis/mediacore/owner'

function useQueryProperty(id?: string, tokkoKey?: string) {
  const skip = !id
  const middlewarev2: boolean = process.env.GATSBY_USE_MIDDLEWARE_V2?.toLowerCase() === 'true'

  const {
    data,
    data: allPropertyData,
    isLoading,
    isError,
    isFetching,
  } = tokkoKey
    ? middlewarev2
      ? useGetPropertyMiddlewareQuery({ PROPERTY_ID: id }, { skip })
      : useGetPropertyQueryTokko({ API_KEY: tokkoKey, ID: id }, { skip })
    : useGetPropertyQueryMediacore(
        {
          CLIENT_ID: process.env.GATSBY_CLIENTID,
          PROP_ID: id,
        },
        { skip },
      )

  return { data, allPropertyData, isLoading, isError, isFetching }
}

export default useQueryProperty
