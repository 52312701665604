import { adapt_development_data } from '@helpers/helper.mediacore'
import { mediacoreApi } from '../mediacoreApi'
import { Development, DevelopmentMediacore } from 'types'

const developmentsExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getDevelopments: builder.query({
      query: ({ PARAMS }) =>
        `site/desarrollos/template/?client=${process.env.GATSBY_CLIENTID}&${PARAMS}`,
      // transformResponse: (data) => ({"meta":{"total_count":data.length},"objects": data.map((prop => adapt_development_data(prop)))})
      transformResponse: (data: DevelopmentMediacore[]) =>
        data.map((dev) => adapt_development_data(dev)),
    }),
    getDevelopment: builder.query({
      query: ({ DEVELOPMENT_ID }) =>
        `site/desarrollos/${DEVELOPMENT_ID}/ficha/?client=${process.env.GATSBY_CLIENTID}&with_units=true`,

      transformResponse: (data: DevelopmentMediacore) => adapt_development_data(data),
    }),
  }),
  overrideExisting: false,
})

export const { useGetDevelopmentsQuery, useGetDevelopmentQuery } = developmentsExtended
