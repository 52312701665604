import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import { useGetJobsQuery } from '@apis/mediacore/jobs'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { Container, Nav } from 'react-bootstrap'
import { RealEstateDataHeaderData, StatePropertiesSlice } from 'types'
import { useLocation } from '@reach/router'
import './index.scss'
import { useSelector } from 'react-redux'
import { getfavorites } from '@helpers/helper.favorites'
import toast, { Toaster } from 'react-hot-toast'
import $ from 'jquery'
import { useGetPushQuery } from '@apis/mediacore/push'
import { getActionByType, getCodeFromType } from '@helpers/helper.actions'

const Boilerplate_Header_1 = (
  props: RealEstateDataHeaderData & {
    development_id: string
    property_id: string
    novelty_id: string
  },
) => {
  const {
    pages,
    global_data,
    hiddenLogo,
    leftSide,
    buttonCompare,
    divisions,
    isInverted,
    background_secondary,
    textUppercase,
    is_short,
    sticky,
    bigContent,
    logoSize,
    development_id,
    property_id,
    novelty_id,
  } = props
  const { divisionPath, id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const logo = global_data.logo
  const logoMobile = global_data.logoMobile
  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })
  const { data: allJobsData } = useGetJobsQuery({ site: siteId })
  //const icons: string = global_data.pageConfig.design.icons

  //const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const { updaterFavorites } = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )!
  const countFavorites = listFavorites.length

  const [open, setOpen] = useState(false)

  const staticBody = () => {
    var body = document.getElementsByTagName('html')[0]
    body.classList.toggle('overflow-hidden')
  }

  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement
    body.style.paddingTop = '0px'
  }, [pathname])

  useEffect(() => {
    setListFavorites(getfavorites('prop', 'template-site'))
  }, [updaterFavorites])

  const SupraNavBar = require(`@components/supraNavBar/1`)?.default

  const [scrollPercentage, setScrollPercentage] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = $(document).height()!
      const clientHeight = $('body').height()!
      const scrollTop = $('body').scrollTop()!
      const percentage = (scrollTop / (scrollHeight - clientHeight)) * 100
      setScrollPercentage(percentage)
    }

    $('body').on('scroll', handleScroll)

    return () => {
      $('body').off('scroll', handleScroll)
    }
  }, [])

  const logoRef = React.useRef<any>(null)

  const isCompiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'

  const currentPage = pages?.find(
    (page) =>
      (pathname.replaceAll('/', '') === page.path.replaceAll('/', '') && !isCompiler) ||
      (isCompiler &&
        pathname !== '/' &&
        pathname.replace(global_data.divisionPath!, '').replaceAll('/', '') ===
          page.path.replaceAll('/', '')) ||
      (pathname.includes('/emprendimiento/') && page.path === '/desarrollo_detalle') ||
      (pathname.includes('/propiedad/') && page.path === '/propiedad_detalle') ||
      (pathname.includes('/novedad/') && page.path === '/novedad_detalle'),
  )

  const { data: allPushData, isLoading: loading } = useGetPushQuery({
    page: currentPage?.id || '',
    data_id: development_id || property_id || novelty_id || '',
  })

  const hasALertTop =
    getActionByType(allPushData, getCodeFromType('Barra Encabezado Timer')).length > 0 ||
    getActionByType(allPushData, getCodeFromType('Barra Encabezado')).length > 0

  return (
    <header
      id="header"
      ref={logoRef}
      className={`blur b header-2 ${is_short ? 'small-height' : ''} ${open ? 'opened' : 'closed'} ${
        hiddenLogo && ' hidden-logo '
      } ${background_secondary && 'background-secondary'}${isInverted ? ' inverted ' : ''}${
        process.env.GATSBY_IS_SITE_COMPILER === 'true' ? ' is_multisite' : ' '
      } ${sticky ? ' position-sticky ' : ''} ${
        parseInt(scrollPercentage.toFixed(2)) > 85 ? ' opacity' : ''
      } ${bigContent ? ' is-extended' : ''} ${hasALertTop ? ' ' : ' notTopCustom'}`}
    >
      <Toaster
        toastOptions={{
          className: 'toast-className',
          style: {
            maxWidth: 'fit-content',
          },
        }}
      />
      <Nav className="brand">
        {process.env.GATSBY_IS_SITE_COMPILER === 'true' && (
          <div className="divisions-nav d-flex align-items-center">
            <Container
              className="d-flex align-items-center justify-lg-content-between"
              fluid
            >
              <Link
                className="px-0"
                style={{ opacity: 1 }}
                to="/"
              >
                <img
                  src={logo}
                  alt=""
                />
              </Link>
              <SupraNavBar
                divisions={divisions}
                divisionPath={divisionPath}
              />
            </Container>
          </div>
        )}
        <div className="container-fluid">
          <div
            className={`row ${
              leftSide ? 'justify-content-lg-start' : 'justify-content-lg-between'
            } align-items-center ${
              process.env.GATSBY_IS_SITE_COMPILER === 'true'
                ? 'justify-content-end'
                : 'justify-content-between'
            }`}
          >
            <div
              className={`col-7 col-lg-1 d-flex justify-content-start justify-content-lg-start ${
                leftSide ? 'order-2 order-lg-2 ' : 'order-1 order-lg-1'
              }`}
            >
              <Link
                to={'/'}
                className="logo d-none d-lg-flex align-items-center"
              >
                <img
                  className="logo-header"
                  style={{
                    maxHeight: logoSize
                      ? (logoRef?.current?.clientHeight * parseInt(logoSize!.toString())) / 100 +
                        'px'
                      : (logoRef?.current?.clientHeight * 55) / 100 + 'px',
                    height: logoSize
                      ? (logoRef?.current?.clientHeight * parseInt(logoSize!.toString())) / 100 +
                        'px'
                      : (logoRef?.current?.clientHeight * 55) / 100 + 'px',
                  }}
                  src={logo}
                ></img>
              </Link>
              <Link
                to={'/'}
                className="logo d-flex align-items-center d-lg-none"
              >
                <img
                  className="logo-header"
                  src={logoMobile}
                  style={{
                    maxHeight: logoSize
                      ? (logoRef?.current?.clientHeight * parseInt(logoSize!.toString())) / 100 +
                        'px'
                      : (logoRef?.current?.clientHeight * 70) / 100 + 'px',
                    height: logoSize
                      ? (logoRef?.current?.clientHeight * parseInt(logoSize!.toString())) / 100 +
                        'px'
                      : (logoRef?.current?.clientHeight * 70) / 100 + 'px',
                  }}
                ></img>
              </Link>
            </div>
            <div
              className={`col-3 col-lg-11  d-flex ${
                leftSide
                  ? 'order-2 order-lg-1 justify-content-lg-start justify-content-end'
                  : 'order-2 order-lg-2 justify-content-end text-right'
              }`}
            >
              <ul
                className={`nav-menu ${
                  leftSide ? 'justify-content-start' : 'justify-content-lg-end'
                }`}
              >
                {makeItemsNav(allSummaryData, allBlogData, pages, allJobsData)
                  .filter((page) => !page.path.includes('detalle'))
                  .map(({ path, title }, index) => (
                    <Nav.Item key={index}>
                      <Link
                        onClick={() => {
                          setOpen(false),
                            document
                              .getElementsByTagName('html')[0]
                              .classList.remove('overflow-hidden')
                        }}
                        className={
                          (!textUppercase ? 'text-uppercase ' : 'text-none ') +
                          pathname +
                          ': ' +
                          +' , ' +
                          path +
                          ' ' +
                          ((pathname.includes(path) && pathname !== '' && path !== '/') ||
                          (pathname.includes('novedad/') && path.includes('novedades')) ||
                          (path === '/' && pathname === '/') ||
                          (pathname.toLowerCase() === divisionPath?.toLowerCase() + '/' &&
                            path === '/')
                            ? 'active'
                            : '')
                        }
                        to={(divisionPath ?? '') + path}
                      >
                        {title}
                      </Link>
                    </Nav.Item>
                  ))}
              </ul>
              {buttonCompare?.value &&
                allSummaryData?.objects?.operation_types?.length > 0 &&
                (countFavorites === 0 ? (
                  <div
                    className="btn btn-white btn-compare disabled-compare border d-lg-flex d-none align-items-center justify-content-center"
                    onClick={() => toast.error('No tienes propiedades favoritas seleccionadas.')}
                  >
                    {screen.width > 992 ? buttonCompare?.value : ''}
                    <div
                      className="mx-3"
                      style={{ fontStyle: 'normal' }}
                    >
                      {countFavorites}
                    </div>
                    <i
                      className={`icon-favoritos-heart` + (countFavorites < 1 && ' disabled ')}
                    ></i>
                  </div>
                ) : (
                  <Link to={buttonCompare?.link}>
                    <div className="btn btn-white btn-compare border d-lg-flex d-none align-items-center justify-content-center">
                      {screen.width > 992 ? buttonCompare?.value : ''}
                      <div
                        className="mx-3"
                        style={{ fontStyle: 'normal' }}
                      >
                        {countFavorites}
                      </div>
                      <i className={`icon-favoritos-heart`}></i>
                    </div>
                  </Link>
                ))}
              <button
                className="d-block d-lg-none"
                id="toggle-nav"
                onClick={() => {
                  setOpen(!open)
                  staticBody()
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="status_nav d-none d-lg-inline">Menú </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </div>

            {buttonCompare?.value && hiddenLogo && (
              <div className="col-9 col-lg-1 order-1 order-lg-3 d-lg-none d-flex align-items-center justify-content-lg-end justify-content-start">
                {allSummaryData?.objects?.operation_types?.length > 0 &&
                  (countFavorites === 0 ? (
                    <div
                      className="btn btn-white btn-compare disabled-compare border d-flex align-items-center justify-content-center"
                      onClick={() => toast.error('No tienes propiedades favoritas seleccionadas.')}
                    >
                      {screen.width > 992 ? buttonCompare?.value : ''}
                      <div
                        className="mx-3"
                        style={{ fontStyle: 'normal' }}
                      >
                        {countFavorites}
                      </div>
                      <i
                        className={`icon-favoritos-heart` + (countFavorites < 1 && ' disabled ')}
                      ></i>
                    </div>
                  ) : (
                    <Link to={buttonCompare.link}>
                      <div className="btn btn-white btn-compare border d-flex align-items-center justify-content-center">
                        {screen.width > 992 ? buttonCompare?.value : ''}
                        <div
                          className="mx-3"
                          style={{ fontStyle: 'normal' }}
                        >
                          {countFavorites}
                        </div>
                        <i className={`icon-favoritos-heart`}></i>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Nav>
    </header>
  )
}

export default Boilerplate_Header_1
