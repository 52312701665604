import {
  Development,
  ObjectTokkoFilters,
  Property,
  ResponseRankingDevQuery,
  ResponseRankingQuery,
} from 'types'
import { mediacoreApi } from '../mediacoreApi'
import {
  getOnlyArrayOperationById,
  getSumaryFilterDefault,
  makeParamsCoordinates,
} from '@helpers/helper.filters'
import { removeDuplicateProperties } from '@helpers/helper.properties'

interface Params {
  API_KEY?: string
  OPERATION?: string
  TYPE: any
  CTAG?: number[]
}

const ownerExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewProperties: builder.query({
      query: ({ params_get, params_post, coordinates, polygonCoordinates }) => ({
        url: `owner/tokko-propiedades/search/${params_get}&client=${process.env.GATSBY_CLIENTID}`,
        // body: makeParamsCoordinates(coordinates, polygonCoordinates),
        body: { ...makeParamsCoordinates(coordinates, polygonCoordinates), ...params_post },
        // body: params_get,
        method: 'POST',
      }),
      transformResponse: ({ meta, objects }) => {
        return { meta, objects: removeDuplicateProperties(objects) }
      },
    }),
    getRanking: builder.query<ResponseRankingQuery[], void>({
      query: () => `owner/externo/tokko-propiedades/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevRanking: builder.query<ResponseRankingDevQuery[], void>({
      query: () => `owner/externo/tokko-desarrollos/ranking/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevelopmentsMiddleware: builder.query<Development[], void>({
      query: () => `owner/externo/tokko-desarrollos/?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getDevelopmentMiddleware: builder.query<Development, { DEVELOPMENT_ID?: string }>({
      query: ({ DEVELOPMENT_ID }) =>
        `owner/externo/tokko-desarrollos/${DEVELOPMENT_ID}?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getPropertyMiddleware: builder.query<Property, { PROPERTY_ID?: string }>({
      query: ({ PROPERTY_ID }) =>
        `owner/tokko-propiedades/${PROPERTY_ID}/ficha?client=${process.env.GATSBY_CLIENTID}`,
    }),
    getNewUnities: builder.query({
      query: ({ DEVELOPMENT_ID }) => ({
        url: `owner/tokko-propiedades/search/?limit=1000&client=${process.env.GATSBY_CLIENTID}`,
        body: { development_id: DEVELOPMENT_ID },
        method: 'POST',
      }),
      transformResponse: ({ objects }) => {
        return objects
      },
    }),
    getTotalFilter: builder.query<ObjectTokkoFilters, Params>({
      query: ({ OPERATION = '', TYPE = [], CTAG = [] }) => ({
        url: `owner/externo/tokko-propiedades/get_search_summary/?client=${
          process.env.GATSBY_CLIENTID
        }${OPERATION ? '&operation_types=' + getOnlyArrayOperationById(OPERATION) : ''}`,
        method: 'GET',
      }),
    }),
    // getSummaryFilters https://staging.mediacore.app/api/
  }),
  overrideExisting: false,
})

export const {
  useGetNewPropertiesQuery,
  useGetRankingQuery,
  useGetDevRankingQuery,
  useGetDevelopmentsMiddlewareQuery,
  useGetDevelopmentMiddlewareQuery,
  useGetPropertyMiddlewareQuery,
  useGetNewUnitiesQuery,
  useGetTotalFilterQuery,
} = ownerExtended
