import { originalTokkoApi } from '../originalTokkoApi'

const propertyExtended = originalTokkoApi.injectEndpoints({
  endpoints: (builder) => ({
    sendContactForm: builder.mutation({
      query: ({ API_KEY, body}) => ({
        url: `webcontact/?key=${API_KEY}`,
        body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useSendContactFormMutation } = propertyExtended
