import { createSlice } from '@reduxjs/toolkit'
import { Novelty } from 'types'

export const noveltiesSlice = createSlice({
  name: 'novelties',
  initialState: {
    tags_active:[],
    novelties_active:[]
  } as {tags_active:number[],novelties_active:Novelty[]},
  reducers: {
    setActiveTags: {
      reducer(state, action) {
        state.tags_active = action.payload
      },
      prepare(tags) {
        return {
          payload: tags,
          meta: '',
          error: '',
        }
      },
    },
    setActiveNovelties: {
      reducer(state, action) {
        state.novelties_active = action.payload
      },
      prepare(novelties) {
        return {
          payload: novelties,
          meta: '',
          error: '',
        }
      },
    },
  },
})
export const {
  setActiveTags,
  setActiveNovelties
} = noveltiesSlice.actions
