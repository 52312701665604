import { createSlice } from '@reduxjs/toolkit'

interface State {
  idToken?: string
  testVar?: string
}

export const userSlice = createSlice({
  name: 'user',
  initialState: { idToken: 'testToken123', testVar: '1' } as State,
  reducers: {
    updateIdToken: {
      reducer(state, action) {
        state.idToken = action.payload
      },
      prepare(idToken) {
        return {
          payload: idToken,
          meta: '',
          error: '',
        }
      },
    },
  },
})

export const { updateIdToken } = userSlice.actions
