import Image from '@components/Image'
import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RealEstateDataFooterData, RealEstateSocial } from 'types'

const Footer_5 = (props: RealEstateDataFooterData) => {
  const { global_data, image_gatsby, backgroundColor, backgroundHexa } = props
  const social = global_data.pageConfig.social
  const icons = global_data?.pageConfig.design.icons || 'Solid'
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const getCurrentYear = (): number => new Date().getFullYear()

  const socialLinkEl = (name: string, link: string, key: number): ReactNode => (
    <>
      <a
        key={key}
        target="_blank"
        aria-label={'Lea más en la red social? ' + name}
        href={link}
        className={'social-link-el ' + (link ? 'd-inline' : 'd-none')}
      >
        <i className={`icon-${name}${iconsStyle}`}></i>
      </a>
    </>
  )

  const socialWrapper = (social: RealEstateSocial): ReactNode[] | null => {
    let socialArr = []

    if (social?.instagram) {
      socialArr.push({ name: 'instagram', link: social?.instagram })
    }
    if (social?.facebook) {
      socialArr.push({ name: 'facebook', link: social?.facebook })
    }
    if (social?.linkedin) {
      socialArr.push({ name: 'linkedin', link: social?.linkedin })
    }
    if (social?.youtube) {
      socialArr.push({ name: 'youtube', link: social?.youtube })
    }
    if (social?.twitter) {
      socialArr.push({ name: 'twitter', link: social?.twitter })
    }
    if (social?.tiktok) {
      socialArr.push({ name: 'tiktok', link: social?.tiktok })
    }

    return socialArr?.length > 0
      ? socialArr?.map((s, i) => (
          <div
            className="d-inline-block"
            key={i}
          >
            {' '}
            {socialLinkEl(s?.name, s?.link, i)}
          </div>
        ))
      : null
  }

  return (
    <section className="footer e">
      <div
        className={`${backgroundColor} banner-item d-flex align-items-center`}
        style={{
          backgroundColor: backgroundColor === 'background-picker' ? backgroundHexa : '',
        }}
      >
        <Container fluid>
          <Row className="mb-0">
            <Col
              className="ps-lg-5"
              lg={6}
            >
              {image_gatsby && (
                <Image
                  className=""
                  objectFit="contain"
                  alt="image-banner"
                  image={image_gatsby!}
                />
              )}
            </Col>
            <Col
              // className="d-flex social align-middle justify-content-lg-end justify-content-center align-items-center me-lg-5 pe-lg-5 mb-5 mb-lg-0"
              className="d-flex social align-middle justify-content-lg-end justify-content-center align-items-center mb-5 pe-lg-5 mb-lg-0"
              lg={6}
            >
              {socialWrapper(social)}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-content">
        <p className="copy text-center">
          Promoted by{' '}
          <a
            target="_blank"
            href="https://mediahaus.com.ar"
          >
            {' '}
            MediaHaus
          </a>{' '}
          - Powered by{' '}
          <a
            target="_blank"
            href="https://mediacore.app/"
          >
            {' '}
            MediaCore
          </a>
          <br />
          <span className="small-text">Todos los derechos reservados ® {getCurrentYear()}</span>
        </p>
      </div>
    </section>
  )
}

export default Footer_5
