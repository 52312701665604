exports.components = {
  "component---src-components-development-detail-tsx": () => import("./../../../src/components/DevelopmentDetail.tsx" /* webpackChunkName: "component---src-components-development-detail-tsx" */),
  "component---src-components-dynamic-page-tsx": () => import("./../../../src/components/DynamicPage.tsx" /* webpackChunkName: "component---src-components-dynamic-page-tsx" */),
  "component---src-components-novelty-detail-tsx": () => import("./../../../src/components/NoveltyDetail.tsx" /* webpackChunkName: "component---src-components-novelty-detail-tsx" */),
  "component---src-components-property-detail-tsx": () => import("./../../../src/components/PropertyDetail.tsx" /* webpackChunkName: "component---src-components-property-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-component-tsx": () => import("./../../../src/pages/component.tsx" /* webpackChunkName: "component---src-pages-component-tsx" */),
  "component---src-pages-tokko-[id]-tsx": () => import("./../../../src/pages/tokko/[id].tsx" /* webpackChunkName: "component---src-pages-tokko-[id]-tsx" */)
}

