import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'leaflet/dist/leaflet.css'
import './src/styles/styles.scss'
import 'animate.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import { wrapRootElement as wrapRootElementBrowser } from './src/wrapElements'
import { wrapPageElement as wrapPageElementBrowser } from './src/wrapElements'

export const wrapRootElement = wrapRootElementBrowser
export const wrapPageElement = wrapPageElementBrowser

// export const onInitialClientRender = () => {
//   window.addEventListener('popstate', () => (window.location.href = window.location.href))
// }
