import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FileSystemNode } from 'gatsby-source-filesystem'
import { CSSProperties } from 'react'
interface ImageProps {
  image: Partial<FileSystemNode> | null
  className: string
  objectFit: CSSProperties['objectFit']
  alt: string
  hrefImage?: string
  style?: CSSProperties
}

const Image = ({ image, hrefImage, className, objectFit, alt, style }: ImageProps) => {
  return image ? (
    <GatsbyImage
      className={className + ' image-br-config'}
      objectFit={objectFit}
      alt={alt}
      image={getImage(image?.url!)!}
      style={style}
    />
  ) : (
    <img
      src={
        hrefImage ||
        'https://images.pexels.com/photos/37347/office-sitting-room-executive-sitting.jpg?cs=srgb&dl=pexels-pixabay-37347.jpg&fm=jpg'
      }
      alt={alt}
      className={className + ' image-br-config'}
      style={{ objectFit: objectFit }}
    />
  )
}

export default Image
