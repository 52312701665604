import { mediacoreApi } from '@apis/mediacoreApi'
import { middlewareTokkoApi } from '@apis//middlewareTokkoApi'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userSlice } from '@slices/userSlice'
import { propertiesSlice } from '@slices/propertiesSlice'
import { originalTokkoApi } from '@apis/originalTokkoApi'
import { gallerySlice } from '@slices/galerySlice'
import { noveltiesSlice } from '@slices/noveltiesSlice'

export const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [propertiesSlice.name]: propertiesSlice.reducer,
    [gallerySlice.name]: gallerySlice.reducer,
    [noveltiesSlice.name]: noveltiesSlice.reducer,
    [mediacoreApi.reducerPath]: mediacoreApi.reducer,
    [middlewareTokkoApi.reducerPath]: middlewareTokkoApi.reducer,
    [originalTokkoApi.reducerPath]: originalTokkoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      mediacoreApi.middleware, //
      middlewareTokkoApi.middleware, //
      originalTokkoApi.middleware //
    ]),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Configure Redux with Typescript following official recommendations, see:
// https://redux-toolkit.js.org/tutorials/typescript
// Configure RTK Query, see:
// https://redux-toolkit.js.org/rtk-query/overview
